.qa_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  /*  margin-bottom: -10px;*/
}
.modal_wrapper {
  padding: 20px;
  width: 400px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.modal_title {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
.password_wrapper {
  display: flex;
  align-items:;
  justify-content: stretch;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 80%;
}
.sign_for_label {
  margin-bottom: 6px;
}
.form_name_text {
  text-align: center;
}
.qa_tag {
  display: flex;
  align-items: center;
  border: 1px solid #bbb;
  border-radius: 5px;
  color: #666;
  padding: 2px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 20px;
}
.tag_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
}
.check_mark {
  align-self: center;
  align-items: center;
}
.modal_button_wrapper {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
}
.modal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin-bottom: 10px;
}
