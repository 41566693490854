.wrapper {
  padding: 0 3rem 0rem 3rem;
  font-size: 0.95em;
  /*  width: 100%;*/
  /*  max-width: 800px;*/
  display: flex;
  /*  flex: 1;*/
  flex-shrink: 1;
  flex-grow: 1;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
}

@media print {
  .wrapper {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin-left: 0 !important;
  }
}

.input_flex {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.left-split {
  width: 40%;
}

.right-split {
  width: 60%;
}

.checkbox_row {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.checkbox {
  padding: 0;
  height: 30px;
}

.radio_label {
  font-size: 0.8em;
}

.subject_title {
  margin-top: 10px;
  margin-left: -20px;
}

.segmentWrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
}
.stretch_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.no_rows_overlay {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subject_title_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.crf_builder_button {
  margin-top: 10px;
}
.protocol_vesion {
  position: absolute;
  width: 80%;
  bottom: 0;
  text-align: center;
  background: white;
  padding-top: 4px;
  padding-bottom: 7px;
  font-size: 12px;
  color: #444;
  border-top: 1px solid #eee;
}
